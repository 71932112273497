:root {
  --black-color: #131527;
  --light-gray: #f4f4f4;
}
@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Anek Gujarati";
  src: url("../fonts/AnekGujarati-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #f4f4f4;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Anek Gujarati", sans-serif;
  font-size: 14px;
  width: 100%;
  background: #fff;
  overflow-x: hidden;
  user-select: none;
}

.container-cust {
  background-color: var(--light-gray);
  min-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
button:focus,
button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
a {
  text-decoration: none;
}

.home-top-gradi {
  position: relative;
  height: 170px;
}

.home-top-gradi::after {
  content: "";
  position: absolute;
  bottom: 0;
  background-image: url(../Images/bg-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  height: 100px;
  right: 0;
}

.home-top-gradi::before {
  content: "";
  position: absolute;
  top: 0;
  background: linear-gradient(45deg, #01052d 12.05%, #020c6a 148.46%);
  height: 170px;
  width: 100%;
}

.drawer-icn span {
  padding: 10px;
  background-color: #1a2261;
  border-radius: 9px;
  display: inline-block;
  cursor: pointer;
}

.common-title h1 {
  font-weight: 600;
  font-size: 21px;
  color: #fff;
}

.white-box {
  box-shadow: 0px 15px 30px 0px #191f3d0d;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid transparent;
}

.white-box:has(.select-box.active) {
  border-color: #495eca;
}

.white-box .select-box.active {
  color: #191919;
}

.white-box .select-box.active svg path {
  stroke: #191919;
}

.icon-box span {
  background-color: #f5f6fb;
  padding: 20px;
  display: inline-block;
  border-radius: 10px;
}

.icon-box span svg {
  height: 23px;
  width: 23px;
}

.title-box h3 {
  font-weight: 600;
  font-size: 14px;
  color: #212523;
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.mar-neg-sec {
  position: relative;
  margin-top: -60px;
  z-index: 1;
}

.white-box .select-box {
  color: #a6a6a6;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.white-box .common-title h1 {
  color: var(--black-color);
}

.white-box .common-title p {
  color: #5c616f;
}

.main-drawer {
  position: absolute;
  /* top: 25%; */
  bottom: 0;
  background-color: white;
  width: 100%;
  max-width: 575px;
  max-height: calc(100% - 25%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 15px;
  overflow-y: auto;
}

.drawer-inner {
  padding: 0 15px;
}

.drawer-inner h3 {
  color: var(--black-color);
  font-weight: 600;
  font-size: 16px;
}

.drawer-inner .question-mcq ul li bdi.active {
  background-color: #0da659;
  color: #fff;
}

.drawer-inner .border-top-notch {
  background-color: #cdd4db;
  border-radius: 30px;
  width: 50px;
  height: 6px;
  margin-bottom: 20px;
  cursor: pointer;
}

.cust-select-radio {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.cust-select-radio li input {
  width: 0;
  height: 0;
  display: none;
}

.cust-select-radio li {
  margin-bottom: 10px;
}

.cust-select-radio li label {
  background-color: #f6f7f8;
  display: block;
  padding: 15px;
  font-weight: 500;
  cursor: pointer;
  border: 0.5px solid #d9d9d9;
  border-radius: 6px;
  color: var(--black-color);
  text-decoration: none;
}

.cust-select-radio li label bdi {
  color: #667588;
}

.cust-select-radio li input:checked ~ label {
  background-color: #495eca;
  color: #fff;
}

.cust-select-radio li input:checked ~ label bdi {
  color: #fff;
}

.offcanvas.cust-off-canvas {
  max-width: 85%;
}

.logo-div-sidebar img {
  width: 140px;
}

.logo-div-sidebar p {
  color: #5c616f;
  font-size: 13px;
  margin-top: 15px;
}

.address-side,
.question-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.address-side li {
  display: flex;
  color: #5c616f;
  font-weight: 500;
  align-items: center;
}

.address-side li a {
  color: #5c616f;
  text-decoration: none;
}

.scrollable-drawer {
  overflow-y: auto;
  max-height: 580px;
  -webkit-overflow-scrolling: touch;
}

.subject-box {
  background-color: #ff7648;
  padding: 15px;
  color: #fff;
  border-radius: 15px;
  height: 100%;
  min-height: 90px;
  position: relative;
  display: block;
  text-decoration: none;
}

.subject-box::after {
  content: "";
  position: absolute;
  right: 0;
  background-image: url(../Images/bubble.svg);
  top: 0;
  background-size: cover;
  width: 120px;
  height: 75px;
  background-repeat: no-repeat;
}

.subject-box .icon-box {
  margin-bottom: 20px;
}

.subject-box .icon-box img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.subject-box span {
  font-weight: 600;
  font-size: 16px;
}
.chap-box span {
  font-size: 18px;
}
.cust-select-radio li label.video-sec {
  font-size: 16px;
}

.subject-box bdi {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

.common-title h3 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
}

.cust-tab-sem {
  background-color: #e8ebee;
  border-radius: 6px;
  padding: 5px;
}

.cust-tab-sem ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.cust-tab-sem ul li label {
  background-color: transparent;
  padding: 8px 25px;
  font-weight: 600;
  color: #5c616f;
  border-radius: 5px;
  white-space: nowrap;
}

.cust-tab-sem ul li input[type="radio"]:checked + label {
  background-color: #fff;
  color: #495eca;
}

.scroll-quiz {
  height: 100%;
}

.container-md:has(.pagination) .scroll-quiz {
  height: calc(100vh - 105px);
  overflow-y: auto;
  overflow-x: hidden;
}

.container-md:has(.pagination .back-arrow) .scroll-quiz {
  height: calc(100vh - 120px);
}
.container-md:has(.pagination.shadow-sm) .pdf-btn-sticky {
  bottom: 49px;
}
.container-md:has(.pagination .back-arrow) .pdf-btn-sticky {
  bottom: 73px;
}

.question-list .question-main-box {
  border: 1px solid #495eca;
  padding: 10px;
  border-radius: 7px;
  margin: 0 0 15px 0;
  position: relative;
}

.question-main-box img,
.drawer-inner img {
  max-width: 100%;
  height: auto;
}
.question-main-box.pdf-generate-new {
  border: 0;
  margin: 0;
  padding: 0;
}

.question-main-box h1 {
  font-weight: 600;
  color: #495eca;
  font-size: 13px;
  display: block;
  padding-top: 12px;
  display: inline-block;
  user-select: none;
  width: auto;
}
.pdf-content h1 {
  padding-bottom: 10px;
}
.question-main-box h1 p {
  margin: 0;
}

.question-solution-icon {
  position: absolute;
  bottom: -8px;
  right: -8px;
  cursor: pointer;
}

.question-counter {
  position: absolute;
  top: -13px;
  background-color: #495eca;
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: 11px;
  /* left: 26px; */
  left: 0;
}
.solution-new {
  /* width: 95%; */
  margin: 0 auto;
  border-radius: 0 0 7px 7px;
  /* border: 1px solid #495eca; */
  border-top: 0;
  flex-wrap: wrap;
}
.solution-new .base-top {
  background-color: #000000;
  color: #fff;
  border-radius: 15px;
  padding: 4px 8px;
}
.solution-new h6 {
  font-size: 12px;
  margin-bottom: 0;
  white-space: nowrap;
}
.solution-new p {
  margin-bottom: 0;
  font-size: 14px;
}

.question-remark {
  position: absolute;
  top: -13px;
  background-color: #ff7648;
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: 11px;
  right: 0;
  max-width: 200px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-clamp:hover {
  text-overflow: unset;
  -webkit-box-orient: inherit;
  max-width: 85%;
}

.question-mcq ul li bdi {
  background-color: #e7edf2;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.question-mcq ul li bdi.active {
  background-color: #0da659;
  color: #fff;
}

.question-mcq ul {
  padding-left: 10px;
}

.question-mcq ul li {
  display: flex;
  align-items: center;
  color: #475467;
  font-size: 13px;
}

.pagination h3 {
  font-size: 15px;
}

.chap-box:after {
  display: none;
}

.sem-div {
  color: #495eca;
  font-size: 12px;
  border: 1px solid #495eca;
  padding: 5px;
  border-radius: 6px;
  font-weight: 600;
}

.container-md:has(section .white-box.no-data) {
  height: 100%;
}

.solutions-drw p {
  font-weight: 400;
  font-size: 14px;
  color: #5c616f;
}

.text-theme {
  color: #495eca !important;
}

.address-side svg {
  width: 45px;
  height: 45px;
}

.social-div ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.social-div ul li {
  margin-right: 10px;
}

.social-div a {
  text-decoration: none;
  font-weight: 600;
  color: #495eca;
}

.privacy-main h3 {
  font-size: 15px;
  font-weight: 600;
  color: #5c616f;
}

.privacy-main p {
  color: #5c616f;
  font-size: 13px;
}

/* ------------------ */
html body[data-scroll-locked] {
  padding: 0 !important;
  margin: 0 auto !important;
  margin-right: auto !important;
  height: 100% !important;
}

[vaul-drawer] {
  touch-action: none;
  transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1);
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  max-width: 575px;
  border-radius: 15px 15px 0 0;
  width: 100%;
  max-height: 80%;
  /* overflow-y: auto; */
}

:focus-visible {
  outline: none;
}

[data-radix-focus-guard] {
  /* background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1 !important; */
}

.cust-overlay-drawer {
  height: 100%;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  top: 0;
  left: 0;
  z-index: 7;
}

/* [vaul-drawer][vaul-drawer-direction=bottom]:after{
  display: none;
} */
[vaul-drawer]:after {
  display: none;
}

.solutions-drw .question-main-box h1 {
  /* user-select: all; */
}

.cust-subject .col-6:nth-child(even) {
  padding-right: 3px;
}

.cust-subject .col-6:nth-child(odd) {
  padding-left: 3px;
}

.cust-m-6 {
  margin-bottom: 6px;
}

.cust-select-txt h5 {
  color: #a6a6a6;
  font-size: 14px;
}

/* new design */
.btn-comn-1,
.btn-comn-1:hover,
.btn-comn-1:focus {
  background-color: #fff;
  padding: 6px 10px;
  font-size: 14px;
  color: #363d4e;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  border-radius: 8px;
  justify-content: center;
}
.btn-comn-1:hover,
.btn-comn-1:focus {
  background-color: #e8ebee;
  color: #363d4e;
}
.pdf-render canvas {
  /* fill: #fff; */
  width: 100% !important;
  height: auto !important;
}
.container-md:has(.pdf-view-sec) .scroll-quiz {
  /* height: calc(100vh - 118px); */
  height: calc(100vh - 126px);
  /* overflow-y: auto; */
  overflow: hidden;
}
.pdf-view-sec:has(.progress) {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.pager {
  list-style-type: none;
  padding: 0;
}
.pdf-render {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pdf-view-sec .progress-bar {
  background-color: #495eca;
  padding: 0 5px;
}
.pdf-view-sec .progress {
  height: 18px;
  width: 100%;
}
.box-icon-new .icon-box {
  right: 10px;
}

/* .pdf-view-sec {
  perspective: 1000px;
}

.pdf-view-sec.next canvas,
.pdf-view-sec.prev canvas {
  transition: 1s;
  transform-style: preserve-3d;
  transform-origin: 0 100px;
  position: relative;
}

.pdf-view-sec.next canvas {
  transform: rotateY(-120deg);
}

.pdf-view-sec.prev canvas {
  transform: rotateY(120deg);
} */

.container-loader {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.container-loader svg {
  transform: rotate(-90deg);
}
.cust-acc .accordion-header button {
  color: #495eca;
  font-size: 16px;
  font-weight: 500;
}
.cust-acc .accordion-button:not(.collapsed) {
  background-color: #495eca;
  color: #fff;
}
.cust-acc .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round'%3E%3Cpath d='M2 5L8 11L14 5'/%3E%3C/svg%3E");
}
.accordion-flush > .accordion-item {
  border-radius: 15px;
  border: 0.5px solid #495eca !important;
  overflow: hidden;
}
.cust-acc .cust-select-radio li label {
  border-radius: 15px;
}
.rounded-10 {
  border-radius: 10px;
}

#root:has(.container-loader) {
  overflow: hidden;
}
.cust-checkbox span {
  border: 1px solid #495eca;
  height: 23px;
  display: block;
  width: 23px;
  border-radius: 50%;
  background-color: #fff;
}
.select-container {
  /* position: absolute;
  top: -13px;
  left: 0; */
}
.cust-checkbox span:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) scale(0);
  transition: 0.2s all;
}
.cust-checkbox input:checked + span {
  background-color: #495eca;
}
.cust-checkbox input:checked + span:before {
  transform: rotate(45deg) scale(1);
}
.question-main-box:has(.cust-checkbox input:checked) {
  background-color: #4d5de71a;
}
.export-btn {
  background-color: #495eca;
  color: #fff;
  border-radius: 6px;
  white-space: nowrap;
}
.cancel-btn-top {
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.pdf-btn-sticky p {
  color: #495eca;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.cust-label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}
.cust-form-control {
  padding: 10px;
  font-size: 14px;
}
.cust-form-control:focus {
  box-shadow: none;
  border: 1px solid #495eca;
}

.single-thumb.range-slider .range-slider__thumb {
  background-color: #495eca;
}

.single-thumb.range-slider .range-slider__track {
  background-color: #ecf0f1;
}

.single-thumb.range-slider .range-slider__range {
  background-color: #495eca;
}
.single-thumb.range-slider .range-slider__range {
  background: #495eca;
}
.single-thumb.range-slider .range-slider__thumb {
  background: #495eca;
  width: 15px;
  height: 15px;
}
.range-slider {
  height: 5px;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

.pdf-questions h1,
.pdf-questions span,
.pdf-questions h1 p {
  /* font-size: 15px;
  font-weight: 600;
  color: #495eca; */
}

.cust-checkbox-square {
  position: relative;
  padding-left: 2rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 500;
}
.cust-checkbox-square input {
  display: none;
}
.cust-option-select-tab ul {
  overflow: unset;
  gap: 10px;
}

.cust-tab-sem ul li .cust-checkbox-square label {
  top: -3px;
  left: 0;
  height: 23px;
  width: 23px;
  border-radius: 0.375rem;
  border-width: 1px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #e8ebee;
  position: absolute;
  cursor: pointer;
  padding: 0;
}
.cust-tab-sem ul li .cust-checkbox-square label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 8px;
  width: 5.5px;
  height: 15px;
  border: solid #495eca;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: 0.3s all;
  opacity: 0;
}

.cust-tab-sem ul li .cust-checkbox-square input:checked ~ label {
  border-color: #495eca;
}
.cust-tab-sem ul li .cust-checkbox-square input:checked ~ label:after {
  opacity: 1;
}
#react-confirm-alert .react-confirm-alert-button-group > button {
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  color: #495eca;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  line-height: normal;
  border: 1px solid #495eca;
}
.react-confirm-alert-overlay.cust-confirm-popup {
  background: rgba(0, 0, 0, 0.5);
}
#react-confirm-alert .react-confirm-alert-body {
  font-family: "Anek Gujarati", sans-serif;
  width: 350px;
  border-radius: 15px;
}
.react-confirm-alert-body h3 {
  font-size: 22px;
  font-weight: 600;
  color: #363d4e;
}
.react-confirm-alert-body p {
  font-size: 15px;
  text-align: center;
}
#react-confirm-alert .react-confirm-alert-button-group > button.btn-danger {
  background-color: #ff3f40;
  color: #fff;
  font-size: 15px;
  border: none;
}

.mjx-chtml {
  /* white-space: normal !important; */
}
.sticky-top-header-all {
  padding: 15px 0;
}

/* ==========generate-option================ */
[vaul-drawer]:has(.download-drawer-main) {
  max-height: fit-content;
}
.download-drawer {
  padding-bottom: 15px;
}
.download-drawer {
  overflow: unset;
}
.not-selectable {
  user-select: none;
}
.pdf-content-container h1 {
  padding: 0;
  color: #495eca;
  /* font-weight: 600; */
  font-size: 14px;
  margin-bottom: 0;
}
.pdf-content-container h1 span {
  color: #495eca;
  /* font-weight: 600; */
}
.black-white .pdf-content-container h1,
.black-white .pdf-content-container .pdf-content h1 span {
  color: #000 !important;
}

.pdf-content-container bdi,
.pdf-content-container ul li {
  background-color: transparent;
  height: auto;
  width: auto;
  padding: 0;
  font-size: 14px;
  color: var(--black-color);
  font-weight: 400;
  margin-right: 5px;
  margin-top: 0 !important;
  list-style-type: none;
}

.pdf-content-container ul {
  padding-left: 10px;
  padding-bottom: 8px;
  margin-bottom: 0;
  /* justify-content: space-between;
  display: flex;
  flex-wrap: wrap; */
}
.pdf-content-container ul li {
  /* flex: 0 0 auto;
  text-wrap: wrap; */
}
/* mjx-container {
  font-size: unset !important;
}
mjx-container mjx-math {
  white-space: break-spaces;
}
mjx-container mjx-stretchy-v > mjx-ext {
  height: inherit;
}
mjx-container mjx-stretchy-v {
  display: inherit;
  height: auto !important;
  vertical-align: middle !important;
} */
.generate-pdf {
  bottom: 10px;
}
#pagebreack {
  page-break-after: always;
}
.pdf-content-container {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: keep-all;
  word-break: keep-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  width: 750px;
  margin: 0 auto;
  /* height: 1099px; */
}
.main-header h1 {
  font-size: 30px;
  font-weight: 600;
}
.main-header ul {
  padding: 0;
}
.main-header {
  /* border-bottom: 1px solid rgb(73, 94, 202); */
  padding-bottom: 10px;
}
/* .pdf-content .right{
  border-left: 1px solid rgb(73, 94, 202);

} */
.question-mcq ul li p {
  margin-bottom: 0;
}

.pdf-content-container ul.mcqs li {
  display: inline-block;
  width: fit-content;
  /* flex-wrap: wrap; */
  margin-right: 0;
}
.pdf-content-container ul.mcqs li p {
  margin-bottom: 0 !important;
}
.pdf-content-container ul.mcqs {
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
  /* flex-wrap: wrap; */
}
.cust-spin {
  width: 23px;
  height: 23px;
}
.pdf-content-container .solution-div p,
.pdf-content-container h1 p {
  margin: 0;
}
.answer-key ul {
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #495eca; */
}

.answer-key ul li {
  margin: 0;
  padding: 5px;
  /* width: 25px;
  height: 25px; */
  border: 1px solid #495eca;
}
.answer-key ul li:not(:first-child) {
  /* border-left: 0; */
}
.black-white .answer-key ul,
.answer-key ul li {
  border-color: #000 !important;
}
.black-white .pdf-content-container .pdf-content img {
  filter: grayscale(1) contrast(1.5) brightness(1) !important;
  mix-blend-mode: multiply !important;
}

/* @media print {
  @page {
    margin: 0;
    size: A4;
  }
  body {
    visibility: hidden;
    margin: 0;
    transform: scale(1);
    transform-origin: top left;
  }
  .print-data {
    visibility: visible;
    display: block;
    position: relative;
    top: 0;
    overflow-y: auto;
  }
} */

/* ---alag css------ */

.question-main-box table,
.solution-new table {
  width: 100% !important;
  overflow-x: auto;
  margin-bottom: 8px;
  border: 1px solid #c1c1c1 !important;
  /* white-space: nowrap; */
  display: table !important;
}
.question-main-box table td,
.solution-new table td,
.pdf-content table td:not(.black-border) {
  width: auto !important;
  border-collapse: collapse;
  border-color: #c1c1c1 !important;
  border: 1px solid;
  padding: 4px 5px !important;
}
.question-main-box table {
  /* width: auto !important; */
  /* flex: 1 1 20px;
  display: flex;
  flex-wrap: wrap;
  border-collapse: collapse; */
}
.question-main-box table thead,
.question-main-box table tbody,
.question-main-box table tr {
  /* display: contents; */
  border: none;
}

.solution-new table td {
}
.solution-new div:has(table) {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.question-main-box img,
.solution-new img {
  max-width: 100%;
  height: auto;
}
.floating-input {
  margin: 5px 0;
}
.floating-input label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  margin-bottom: 0;
  line-height: normal;
  transition: all 0.2s linear, border-color 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
  background-color: #fff;
  padding: 0 5px;
  color: #0000008a;
  opacity: 0;
  visibility: hidden;
}
.floating-input .cust-form-control::placeholder {
  /* color: transparent;  */
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s linear, border-color 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
}
.floating-input .cust-form-control:focus ~ label,
.floating-input .cust-form-control:not(:placeholder-shown) ~ label {
  top: 0;
  font-size: 12px;
  color: #000;
  opacity: 1;
  visibility: visible;
}

.floating-input .cust-form-control:focus::placeholder,
.floating-input .cust-form-control:not(:placeholder-shown)::placeholder {
  color: transparent;
}
.cust-legend legend {
  background-color: #fff;
  color: #495eca;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: -6%;
  z-index: 1;
  width: auto;
}
.cust-legend {
  border: 1px solid #dee2e6;
  position: relative;
  margin-top: 15px;
  border-radius: 8px;
  margin-bottom: 0;
}
.answer-border-table,
.answer-border-table td {
  border-collapse: collapse;
}
.answer-border-table td {
  padding: 8px;
  border: 1px solid #000 !important;
}
.answer-border-table caption {
  color: #000;
  caption-side: top; /* Ensures caption is positioned at the top */
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px; /* Adds spacing between the caption and the table */
  font-size: 20px;
}
.questions-bold,
.questions-bold > *,
.questions-bold .mjx-chtml {
  font-weight: 800 !important;
}
.questions-medium,
.questions-medium > *,
.questions-medium .mjx-chtml {
  font-weight: 600 !important;
}
.questions-light,
.questions-light > *,
.questions-light .mjx-chtml {
  font-weight: 400 !important;
}
.exam-name {
  font-size: 22px !important;
}

.total-mark {
  display: flex;
  gap: 5px;
}
.total-mark li {
  /* list-style-type: none;
  border: 1px solid #fff;
  height: 40px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center; */

  list-style-type: none;
  border: 2px solid #ffffff70;
  height: 35px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 2px 5px 0px 5px;
  gap: 0;
  border-radius: 5px;
}
.total-mark .total-marks-div {
  width: auto;
  padding: 2px 7px 0px 7px;
}
.total-mark li.total-marks-div span:first-child {
  font-size: 13px;
}
.total-mark li .sup-text {
  position: relative;
  right: -7px;
  top: 5px;
}
.total-mark li span {
  font-weight: 500;
  line-height: 1;
  font-size: 13px;
  color: #ffffffbf;
}
.total-mark li span:first-child {
  font-size: 11px;
}
.bg-theme {
  background-color: #495eca !important;
}
.cancel-btn {
  background-color: #ff3f40;
  border: 0;
  padding: 3px 8px;
  border-radius: 0.375rem;
}
.check-btn {
  background-color: #495eca;
  border: 0;
  padding: 3px 8px;
  border-radius: 0.375rem;
}

/* ============================end================== */

/* ==================app-popup======================  */
.app_popup_modal .modal-content {
  border: 0;
  border-radius: 15px;
}
.app_popup_modal .close-btn {
  position: absolute;
  right: -7px;
  top: -15px;
  background-color: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app_popup_modal .close-btn svg {
  width: 15px;
  height: 15px;
}

/* =================media query ================= */

@media (min-width: 1120px) {
  .container-cust,
  body,
  html {
    /* max-width: 1120px; */
    max-width: 575px;
    margin: 0 auto;
  }
}

@media (max-width: 1120px) {
  [vaul-drawer] {
    max-width: 100%;
    /* left: 50%;
    transform: translateX(-50%) translate3d(0, 100%, 0) !important; */
  }
}

@media (max-width: 991px) {
  .subject-box bdi {
    font-size: 15px;
  }
}

@media (max-width: 575px) {
  .white-box {
    padding: 8px;
  }

  .icon-box span {
    padding: 10px;
  }

  .title-box h3 {
    font-size: 14px;
  }

  .white-box .common-title h1 {
    font-size: 16px;
  }

  .white-box .common-title p,
  .white-box .select-box {
    font-size: 12px;
  }

  .cust-select-radio li label {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-color);
  }

  .white-box .logo-div img {
    width: 150px !important;
  }

  .drawer-inner h3 {
    font-size: 17px;
  }

  .address-side li {
    font-size: 13px;
  }

  .common-title h3 {
    font-size: 14px;
  }

  .icon-box .btn-comn-1 {
    padding: 2px;
  }
  .cust-acc .accordion-header button {
    font-size: 14px;
  }
  .cust-acc .accordion-body {
    padding: 8px;
  }
}
